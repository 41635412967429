<template>
  <div>
    <GoogleMap
      :passOperationalDataDriversOnMap="passDriversOnMap"
      :passOperationalDataOrdersOnMap="passOrdersOnMap"
      :passSelectedBoardAddressOnMap="passBoardAddressOnMap"
      :passSelectedDestinationAddressOnMap="passDestinationAddressOnMap"
      :passSelectedTransit1AddressOnMap="passTransit1AddressOnMap"
      :passSelectedTransit2AddressOnMap="passTransit2AddressOnMap"
      :passSelectedTransit3AddressOnMap="passTransit3AddressOnMap"
      :passSelectedTransit4AddressOnMap="passTransit4AddressOnMap"
      @markerSettedOnMapToNew="showMarkerSettedOnMapToNew($event)"
      @showOrderDetailByIdFromMap="actionForOrderDetailByIdFromMap($event)"
      @showMesssageFromMap="actionForMessageFromMap($event)"
      @setDriverToDetailFromMap="actionForSetDriverToDetailFromMap($event)"
      @actualMapBounds="actionForActualMapBounds($event)"
      :passFindDriverOnMap="passFindDriverIdOnMap"
      :passFindDriverOnMapTrigger="passFindDriverIdOnMapTrigger"
      :passFindPositionOnMap="passFindPositionCoordinatesOnMap"
      :switchDarkMapModel="switchDarkMapModel"
      :pathDriverSetting="pathDriver.value"
      :iconDriverSetting="iconDriver.value"
      :iconDriverLabelSetting="iconDriverLabel.value"
      :orderMarkerSetting="orderMarker.value"
      :driverSpeedSetting="driverSpeed"
    />
    <!-- Panel with position: top center of the page -->
    <div
      v-show="showInfoPanel"
      style="
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 0;
        margin-top: 10px;
        margin-left: -130px;
      "
    >
      <!-- Informačný panel s hodnotou "Vyťaženosť vodičov" a "Index záťaže taxislužby" -->
      <v-card width="260" class="mx-auto">
        <p class="my-0">
          <center>
            <v-icon
              small
              :title="$t('Console.infoUtilizationRatio_title')"
              :class="
                operationalStatus.utilizationRatio > 95
                  ? 'red--text'
                  : operationalStatus.utilizationRatio > 85
                  ? 'orange--text'
                  : operationalStatus.utilizationRatio > 65
                  ? 'green--text'
                  : operationalStatus.utilizationRatio < 35
                  ? 'orange--text'
                  : 'gray--text'
              "
              >mdi-gauge</v-icon
            >
            {{ operationalStatus.utilizationRatio + "%" }}
            <span style="color: LightGray">| </span>

            <v-icon
              small
              :title="$t('Console.infoLoadRatio_title')"
              :class="
                operationalStatus.loadRatio > 1.45
                  ? 'red--text'
                  : operationalStatus.loadRatio > 1.05
                  ? 'orange--text'
                  : operationalStatus.loadRatio > 0.85
                  ? 'green--text'
                  : operationalStatus.loadRatio < 0.45
                  ? 'orange--text'
                  : 'gray--text'
              "
              >mdi-heart-pulse</v-icon
            >
            {{ operationalStatus.loadRatio + "" }}
            <span style="color: LightGray">| </span>

            <v-icon
              small
              :title="$t('Console.infoImmediateAvailabilityRatio_title')"
              :class="
                operationalStatus.immediateAvailabilityRatio > 2.5
                  ? 'red--text'
                  : operationalStatus.immediateAvailabilityRatio > 1.45
                  ? 'orange--text'
                  : operationalStatus.immediateAvailabilityRatio > 0.25
                  ? 'green--text'
                  : operationalStatus.immediateAvailabilityRatio < 0.25
                  ? 'orange--text'
                  : 'gray--text'
              "
              >mdi-car-multiple</v-icon
            >
            {{ operationalStatus.immediateAvailabilityRatio + "" }}

            <span style="color: LightGray">|</span>
            <!-- <v-icon
              small
              :title="$t('Console.infoCountDriversInStand_title')"
              :class="countDriversInStand > 0 ? 'blue--text' : 'gray--text'"
            >
              mdi-parking</v-icon
            > -->
            <v-btn
              :title="$t('Console.infoListOfDriversPerStand_title')"
              small
              icon
              color="accent"
              :disabled="countDriversInStand == 0"
              :class="
                countDriversInStand > 0
                  ? 'blue--text ma-0 pa-0'
                  : 'gray--text ma-0 pa-0'
              "
              @click="showListOfDriversPerStand = !showListOfDriversPerStand"
              ><v-icon small>mdi-parking</v-icon></v-btn
            >{{ countDriversInStand + "" }}<br />

            <v-icon small :title="$t('Console.infoCounterPENDING_title')"
              >mdi-routes-clock</v-icon
            >
            {{ operationalStatus.counterPENDING + "" }}
            <span style="color: LightGray">|</span>
            <v-icon small :title="$t('Console.infoSumPerformedOrders_title')"
              >mdi-routes</v-icon
            >
            {{ operationalStatus.sumPerformedOrders + "" }}
            <span style="color: LightGray">|</span>
            <v-icon small :title="$t('Console.infoSumBusyDrivers_title')"
              >mdi-account-multiple-remove-outline</v-icon
            >
            {{ operationalStatus.sumBusyDrivers + ""
            }}{{
              operationalStatus.counterDriversMoreThanOneOrder > 0
                ? "/" + operationalStatus.counterDriversMoreThanOneOrder + ""
                : ""
            }}
            <span style="color: LightGray">|</span>
            <v-icon small :title="$t('Console.infoCounterOnlineFREE_title')"
              >mdi-account-multiple-check-outline</v-icon
            >
            {{ operationalStatus.counterOnlineFREE + "" }}
            <span style="color: LightGray">|</span>
            <v-icon small :title="$t('Console.infoCounterON_BREAK_title')"
              >mdi-coffee-to-go-outline</v-icon
            >
            {{ operationalStatus.counterON_BREAK + "" }}
            <v-btn
              :title="$t('Console.infoListOfDriversWorkRequests_title')"
              small
              icon
              color="accent"
              class="ma-0 pa-0"
              @click="showListOfDriversWorkRequests()"
              ><v-icon small>mdi-briefcase-clock-outline</v-icon></v-btn
            >
          </center>
        </p>
        <!-- list of drivers on stand -->
        <v-divider v-show="showListOfDriversPerStand" class="py-0"></v-divider>
        <v-list v-show="showListOfDriversPerStand" disabled>
          <v-list-item-group>
            <v-list-item
              v-for="driver in listOfDriversPerStand"
              :key="driver.id"
              :title="driver.name"
              dense
              class="px-1"
            >
              <v-list-item-avatar class="pa-0 ma-0">
                <v-icon
                  class="pa-0 ma-0"
                  :class="
                    driver.workStatus === 'ONLINE'
                      ? 'green--text'
                      : driver.workStatus === 'ON_BREAK'
                      ? 'orange--text'
                      : 'gray--text'
                  "
                  >{{
                    driver.workStatus == "ONLINE"
                      ? "mdi-account-outline"
                      : driver.workStatus == "ON_BREAK"
                      ? "mdi-coffee-to-go-outline"
                      : "mdi-alien-outline"
                  }}</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-title>
                  <!--  {{ displayOnlyForAdmin ? driver.id + " | " : "" }}  -->
                  {{ driver.internalName ? driver.internalName + " - " : "" }}
                  {{ driver.firstName }}
                  {{ driver.lastName }}
                  {{ driver.standId ? " |" : "" }}
                  <!--{{ driver.standId ? " " + driver.standId : "" }}-->
                  {{
                    driver.standInternalName
                      ? " " + driver.standInternalName
                      : ""
                  }}
                  {{
                    driver.standName && !driver.standInternalName
                      ? " " + driver.standName
                      : ""
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    driver.vehicle
                      ? (driver.vehicle.internalName
                          ? driver.vehicle.internalName + " - "
                          : "") +
                        driver.vehicle.brand +
                        " " +
                        driver.vehicle.model +
                        " " +
                        driver.vehicle.lpn +
                        " "
                      : ""
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
    <div style="z-index: 2; position: absolute; left: 0; top: 0; margin: 10px">
      <OrderListPanel
        :passOperationalDataOrdersToList="passOrdersToList"
        :progressStatus="progressStatus"
        @showOrderDetailById="actionForOrderDetailByIdFromList($event)"
        @findPositionCoordinatesToMap="
          actionForFindPositionCoordinatesOnMap($event)
        "
        @switchOnlyActiveOrder="actionForSwitchOnlyActiveOrder($event)"
        @redistributeOperationalData="redistributeOperationalData($event)"
      />
    </div>
    <div style="z-index: 3; position: absolute; right: 0; top: 0; margin: 10px">
      <!--  <OrderGroupPanel
        :passSelectedOrderId="passOrderId"
        @showBoardAddressOnMap="showBoardAddressOnMap($event)"
        @showDestinationAddressOnMap="showDestinationAddressOnMap($event)"
        @showTransit1AddressOnMap="showTransit1AddressOnMap($event)"
        @showTransit2AddressOnMap="showTransit2AddressOnMap($event)"
        @showTransit3AddressOnMap="showTransit3AddressOnMap($event)"
        @showTransit4AddressOnMap="showTransit4AddressOnMap($event)"
      />-->
      <v-card
        :width="$vuetify.breakpoint.smAndDown ? '' : '510'"
        class="mx-auto"
      >
        <v-toolbar color="secondary" dense dark>
          <v-app-bar-nav-icon
            @click.stop="showPanel = !showPanel"
            :title="$t('Console.panelActions')"
          >
            <v-icon v-if="showPanel">mdi-menu-down</v-icon>
            <v-icon v-else>mdi-menu-up</v-icon>
          </v-app-bar-nav-icon>
          <!-- Zobrazit nazov panela, iba pri minimalizovani. Ak je otvoreny, je jasne co tam je. Pri malom displeji iba ikonu -->
          <v-toolbar-title
            v-show="!showPanel"
            v-if="$vuetify.breakpoint.smAndDown"
            class="pl-0"
            ><v-icon>mdi-tools</v-icon></v-toolbar-title
          >
          <v-toolbar-title v-show="!showPanel" v-else>{{
            $t("Console.panelActions")
          }}</v-toolbar-title>
          <v-tabs v-model="tab" v-show="showPanel">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              href="#tab-new"
              class="px-2"
              style="min-width: 48px !important"
              :title="$t('Console.tabNew_title')"
            >
              <v-icon left small>fas fa-pen</v-icon>
              <span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("Console.tabNew")
              }}</span>
            </v-tab>
            <v-tab
              href="#tab-detail"
              class="px-2"
              style="min-width: 48px !important"
              :title="$t('Console.tabDetail_title')"
            >
              <v-icon left small>fas fa-tasks</v-icon>
              <span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("Console.tabDetail")
              }}</span>
            </v-tab>
            <v-tab
              href="#tab-messages"
              class="px-2"
              style="min-width: 48px !important"
              :title="$t('Console.tabMessages_title')"
            >
              <v-icon left small>fas fa-envelope-open</v-icon>
              <span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("Console.tabMessages")
              }}</span>
            </v-tab>
            <v-tab
              href="#tab-settings"
              class="px-2"
              style="min-width: 48px !important"
              :title="$t('Console.tabSettings_title')"
            >
              <v-icon left small>fas fa-tools</v-icon>
              <span v-show="$vuetify.breakpoint.lgAndUp">{{
                $t("Console.tabSettings")
              }}</span>
            </v-tab>
          </v-tabs>
        </v-toolbar>

        <v-tabs-items
          class="overflow-y-auto"
          v-model="tab"
          v-show="showPanel"
          touchless
        >
          <v-tab-item value="tab-new">
            <v-card flat>
              <v-card-text class="pa-1">
                <div>
                  <OrderNewPanel
                    @showBoardAddressOnMap="showBoardAddressOnMap($event)"
                    @showDestinationAddressOnMap="
                      showDestinationAddressOnMap($event)
                    "
                    @showTransit1AddressOnMap="showTransit1AddressOnMap($event)"
                    @showTransit2AddressOnMap="showTransit2AddressOnMap($event)"
                    @showTransit3AddressOnMap="showTransit3AddressOnMap($event)"
                    @showTransit4AddressOnMap="showTransit4AddressOnMap($event)"
                    :passSelectedMarkerSettedOnMapToNew="
                      passMarkerSettedOnMapToNew
                    "
                    :passBoundsFromMapToNew="passMapBounds"
                    @showOrderDetailByIdFromNewOrder="
                      actionForOrderDetailByIdFromNew($event)
                    "
                    @setExtension="actionForSetExtension($event)"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- eager potrebne nech sa komponent nacita hned a nie az po prvom kliknuti, alebo to nejako inaksie vymysliet -->
          <v-tab-item value="tab-detail" eager>
            <v-card flat>
              <v-card-text class="pa-1">
                <div>
                  <OrderDetailPanel
                    :selectedOrderId="passOrderId"
                    :selectedOrderTrigger="passOrderTrigger"
                    :selectedDriverId="passDriverId"
                    :selectedDriverTrigger="passDriverTrigger"
                    :passBoundsFromMapToDetail="passMapBounds"
                    :selectedExtension="passExtension"
                    @showMessagefromOrderDetail="
                      actionForMessageFromOrderDetail($event)
                    "
                    @passFindDriverIdToMap="actionForFindDriverIdOnMap($event)"
                    @passFindPositionCoordinatesToMap="
                      actionForFindPositionCoordinatesOnMap($event)
                    "
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-messages" eager>
            <v-card max-width="510" flat>
              <v-card-text class="pa-1">
                <div>
                  <!--:passOperationalDataMessagesToList="passMessagesToList"-->
                  <OrderMessagesPanel
                    :selectedOrderIdMessage="passOrderIdFromDetailToMessage"
                    :passOperationalDataMessagesToMessagesList="allMessages"
                    :passMessageFromNotification="
                      changeMessageTabAndSendMessageFromNotification
                    "
                    @orderIdFromMessage="
                      actionForOrderDetailByIdFromMessage($event)
                    "
                    @changeTabToMessage="
                      actionForChangeTabToMessageFromMessage($event)
                    "
                    @changeTabToDetail="
                      actionForChangeTabToDetailFromMessage($event)
                    "
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-settings" eager>
            <v-container class="pa-2" style="height: 600px; max-height: 80vh">
              <v-card max-width="490" flat
                ><v-card-subtitle class="px-1 py-0"
                  >{{ $t("Console.settings.onlineStatus_subtitle") }}
                </v-card-subtitle>
                <v-list class="pa-0">
                  <v-list-item
                    dense
                    class="px-1 py-0"
                    :title="$t('Console.settings.utilizationRatio_title')"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon>mdi-gauge</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("Console.settings.utilizationRatio") +
                        ": " +
                        operationalStatus.utilizationRatio +
                        "%"
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t(
                          "Console.settings.utilizationRatio_sumActiveDrivers"
                        ) +
                        ": " +
                        operationalStatus.sumActiveDrivers +
                        (operationalStatus.counterON_BREAK > 0
                          ? " (+" +
                            operationalStatus.counterON_BREAK +
                            " " +
                            $t(
                              "Console.settings.utilizationRatio_counterON_BREAK"
                            ) +
                            ")"
                          : "") +
                        " | " +
                        $t("Console.settings.utilizationRatio_sumBusyDrivers") +
                        ": " +
                        operationalStatus.sumBusyDrivers +
                        " | " +
                        $t(
                          "Console.settings.utilizationRatio_counterOnlineFREE"
                        ) +
                        ": " +
                        operationalStatus.counterOnlineFREE
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    dense
                    class="px-1"
                    :title="$t('Console.settings.loadRatio_title')"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon>mdi-heart-pulse</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("Console.settings.loadRatio") +
                        ": " +
                        operationalStatus.loadRatio
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("Console.settings.loadRatio_sumActiveOrders") +
                        ": " +
                        operationalStatus.sumActiveOrders +
                        " | " +
                        $t("Console.settings.loadRatio_counterPENDING") +
                        ": " +
                        operationalStatus.counterPENDING +
                        " | " +
                        $t("Console.settings.loadRatio_sumPerformedOrders") +
                        ": " +
                        operationalStatus.sumPerformedOrders
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    dense
                    class="px-1"
                    :title="
                      $t('Console.settings.immediateAvailabilityRatio_title')
                    "
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon>mdi-car-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("Console.settings.immediateAvailabilityRatio") +
                        ": " +
                        operationalStatus.immediateAvailabilityRatio
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t(
                          "Console.settings.immediateAvailabilityRatio_counterOnlineFREE"
                        ) +
                        ": " +
                        operationalStatus.counterOnlineFREE +
                        " | " +
                        $t(
                          "Console.settings.immediateAvailabilityRatio_counterON_BREAK"
                        ) +
                        ": " +
                        operationalStatus.counterON_BREAK +
                        " | " +
                        $t(
                          "Console.settings.immediateAvailabilityRatio_counterDriversMoreThanOneOrder"
                        ) +
                        ": " +
                        operationalStatus.counterDriversMoreThanOneOrder
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-subtitle class="pa-1">{{
                  $t("Console.settings.settings_subtitle")
                }}</v-card-subtitle>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-switch
                      v-model="isDark"
                      hide-details
                      :label="$t('Console.settings.darkGui')"
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-switch
                      v-model="switchDarkMapModel"
                      hide-details
                      :label="$t('Console.settings.darkMap')"
                      dense
                      @change="setCookieDarkMapModel(switchDarkMapModel)"
                    ></v-switch>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-switch
                      v-model="showInfoPanel"
                      hide-details
                      :label="$t('Console.settings.showInfoPanel')"
                      dense
                      @change="setCookieShowInfoPanel(showInfoPanel)"
                    ></v-switch>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-switch
                      v-model="driverSpeed"
                      hide-details
                      :label="$t('Console.settings.driverSpeed')"
                      dense
                      @change="setCookieDriverSpeed(driverSpeed)"
                    ></v-switch>
                  </v-col>
                  <!-- ignoreSystemMessages switch, nezapamatavat v cookie -->
                  <v-col class="py-0" cols="12" md="6">
                    <v-switch
                      v-model="ignoreSystemMessages"
                      hide-details
                      :label="$t('Console.settings.ignoreSystemMessages')"
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-switch
                      v-model="playNotificationSound"
                      hide-details
                      :label="$t('Console.settings.playNotificationSound')"
                      dense
                      @change="
                        setCookiePlayNotificationSound(playNotificationSound)
                      "
                    ></v-switch>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <!-- on change set to cookies -->
                    <v-select
                      v-model="playNotificationSoundFile"
                      :items="$t('Console.settings.notificationSoundSettings')"
                      item-text="text"
                      item-value="value"
                      :label="$t('Console.settings.playNotificationSoundFile')"
                      @change="
                        setCookiePlayNotificationSoundFile(
                          playNotificationSoundFile
                        )
                      "
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-select
                      v-model="iconDriver.value"
                      :items="$t('Console.settings.iconDriverSettings')"
                      item-text="text"
                      item-value="value"
                      :label="$t('Console.settings.iconDriver')"
                      @change="setCookieIconDriverSettings(iconDriver.value)"
                    ></v-select> </v-col
                  ><v-col class="py-0" cols="12" md="6">
                    <v-select
                      v-model="iconDriverLabel.value"
                      :items="$t('Console.settings.iconDriverLabelSettings')"
                      item-text="text"
                      item-value="value"
                      :label="$t('Console.settings.iconDriverLabel')"
                      @change="
                        setCookieIconDriverLabelSettings(iconDriverLabel.value)
                      "
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <!-- on change set to cookies setCookiePathDriverSettings -->
                    <v-select
                      v-model="pathDriver.value"
                      :items="$t('Console.settings.pathDriverSettings')"
                      item-text="text"
                      item-value="value"
                      :label="$t('Console.settings.pathDriver')"
                      @change="setCookiePathDriverSettings(pathDriver.value)"
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-select
                      v-model="orderMarker.value"
                      :items="$t('Console.settings.showPendingOrdersSettings')"
                      item-text="text"
                      item-value="value"
                      :label="$t('Console.settings.showPendingOrders')"
                      @change="setCookieOrderMarkerSettings(orderMarker.value)"
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field
                      v-model="outbandPrefix"
                      :label="$t('Console.settings.outbandPrefix')"
                      @change="setCookieOutbandPrefix(outbandPrefix)"
                      clearable
                      @click:clear="
                        outbandPrefix = '';
                        deleteCookieOutbandPrefix();
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-1 pb-2">
                  <v-btn
                    color="info"
                    @click="getAutomatStatus()"
                    :title="$t('Console.settings.btnAutomatSwitch_title')"
                  >
                    <v-icon left>mdi-robot-outline</v-icon>
                    {{ $t("Console.settings.btnAutomatSwitch") }}
                  </v-btn>
                  <v-btn
                    color="info"
                    @click="getPbxStatus()"
                    class="ml-1"
                    :title="$t('Console.settings.btnPbxSwitch_title')"
                  >
                    <v-icon left>mdi-phone-voip</v-icon>
                    {{ $t("Console.settings.btnPbxSwitch") }}
                  </v-btn>
                  <!-- Button to open page https://status.websupport.sk/ in new tab -->
                  <v-btn
                    @click="openDatacenterStatus"
                    class="ml-1"
                    plain
                    :title="$t('Console.settings.btnDatacenterStatus_title')"
                  >
                    <v-icon left>mdi-server-security</v-icon>
                    {{ $t("Console.settings.btnDatacenterStatus") }}
                  </v-btn>
                  <!-- Help button to open helpDialog in right -->
                  <v-btn
                    icon
                    @click="helpDialog = true"
                    class="ml-auto"
                    :title="$t('Console.settings.btnHelp_title')"
                  >
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!-- Dialog na zapnutie automatu -->
    <v-dialog v-model="automatEnableDialog" max-width="440">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("Console.automatEnableDialog.title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("Console.automatEnableDialog.text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(automatEnableDialog = false), changeAutomat(true)"
          >
            {{ $t("Console.automatEnableDialog.btnYes") }}
          </v-btn>
          <v-btn color="error" text @click="automatEnableDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na vypnutie automatu -->
    <v-dialog v-model="automatDisableDialog" max-width="440">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("Console.automatDisableDialog.title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("Console.automatDisableDialog.text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(automatDisableDialog = false), changeAutomat(false)"
          >
            {{ $t("Console.automatDisableDialog.btnYes") }}
          </v-btn>
          <v-btn color="error" text @click="automatDisableDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na prepnutie telefonnej ustredne - pbxDialog -->
    <v-dialog v-model="pbxDialog" max-width="500">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("Console.pbxDialog.title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("Console.pbxDialog.text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-3"
            dark
            class="pr-3"
            @click="(pbxDialog = false), changePbx('dispatching')"
            ><v-icon left>mdi-face-man-outline</v-icon>
            {{ $t("Console.pbxDialog.btnPbxDispatching") }}
          </v-btn>
          <v-btn
            color="orange darken-3"
            dark
            class="pr-3"
            @click="(pbxDialog = false), changePbx('non-dispatching')"
            ><v-icon left>mdi-account-tie-outline</v-icon>
            {{ $t("Console.pbxDialog.btnPbxNonDispatching") }}
          </v-btn>
          <v-btn
            color="red darken-3"
            dark
            class="pr-3"
            @click="(pbxDialog = false), changePbx('offline')"
            ><v-icon left>mdi-phone-off-outline</v-icon>
            {{ $t("Console.pbxDialog.btnPbxOffline") }}
          </v-btn>
          <v-btn color="error" text @click="pbxDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog so zoznamom otvorenych poziadaviek na pracovnu zmenu-->
    <v-dialog
      v-model="workShiftRequestListDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderDetail.workShiftRequestListDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in worklogRequestList"
                :key="i"
                inactive="true"
                @click="setDriverToMessage(item.driverId)"
              >
                <!--<v-list-item
                v-for="(item, i) in worklogRequestList"
                :key="i"
                @click="
                  driverModel = item.driverId;
                  workShiftRequestListDialog = false;
                  showEditDriverWorkShiftDialog();
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-information-outline</v-icon>
                </v-list-item-icon>-->
                <v-list-item-avatar>
                  <v-icon
                    :color="
                      item.status == 'NEW'
                        ? 'orange'
                        : item.status == 'CONFIRMED'
                        ? 'green'
                        : 'grey'
                    "
                    >{{
                      item.type == "ON_BREAK"
                        ? "mdi-coffee-outline"
                        : item.type == "OFFLINE"
                        ? "mdi-home-import-outline"
                        : "mdi-briefcase-clock-outline"
                    }}</v-icon
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.createdAt | formatDateShort }}
                    {{ " | " + $t("worklogRequestStatus." + item.status) }}
                    {{ " | " + $t("worklogRequestType." + item.type) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.driverFirstName }}
                    {{ " " + item.driverLastName }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="workShiftRequestListDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Snackbar V STREDE, DOLNY, o novej sprave 1-->
    <v-snackbar
      v-model="snackbar1"
      :timeout="snackbarObject1.timeout"
      dark
      dense
      min-width="400"
      max-width="900"
      class="pa-0 ma-0"
      :color="messageTypeColor[snackbarObject1.type]"
    >
      <v-layout align-center py-0 pr-4>
        <v-icon class="pr-3" dark large>{{ snackbarObject1.icon }}</v-icon>
        <v-layout column py-0>
          <div>
            <strong>{{ snackbarObject1.title }}</strong>
          </div>
          <div>{{ snackbarObject1.text }}</div>
        </v-layout>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="
            changeMessageTab(
              snackbarObject1.type,
              snackbarObject1.fromDriverId,
              snackbarObject1.toDriverId,
              snackbarObject1.toOrderId,
              $t('Console.btnChatOk')
            ),
              (snackbar1 = false)
          "
        >
          {{ $t("Console.btnChatOk") }}
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="
            changeMessageTab(
              snackbarObject1.type,
              snackbarObject1.fromDriverId,
              snackbarObject1.toDriverId,
              snackbarObject1.toOrderId,
              $t('Console.btnChatCalling')
            ),
              (snackbar1 = false)
          "
        >
          {{ $t("Console.btnChatCalling") }}
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="
            changeMessageTab(
              snackbarObject1.type,
              snackbarObject1.fromDriverId,
              snackbarObject1.toDriverId,
              snackbarObject1.toOrderId,
              ''
            ),
              (snackbar1 = false)
          "
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="snackbar1 = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!--Snackbar V STREDE, HORNY, o novej sprave 2-->
    <v-snackbar
      v-model="snackbar2"
      :timeout="snackbarObject2.timeout"
      dark
      dense
      min-width="400"
      max-width="900"
      class="pb-10 mb-10"
      :color="messageTypeColor[snackbarObject2.type]"
    >
      <v-layout align-center py-0 pr-4>
        <v-icon class="pr-3" dark large>{{ snackbarObject2.icon }}</v-icon>
        <v-layout column py-0>
          <div>
            <strong>{{ snackbarObject2.title }}</strong>
          </div>
          <div>{{ snackbarObject2.text }}</div>
        </v-layout>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="
            changeMessageTab(
              snackbarObject2.type,
              snackbarObject2.fromDriverId,
              snackbarObject2.toDriverId,
              snackbarObject2.toOrderId,
              $t('Console.btnChatOk')
            ),
              (snackbar2 = false)
          "
        >
          {{ $t("Console.btnChatOk") }}
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="
            changeMessageTab(
              snackbarObject2.type,
              snackbarObject2.fromDriverId,
              snackbarObject2.toDriverId,
              snackbarObject2.toOrderId,
              $t('Console.btnChatCalling')
            ),
              (snackbar2 = false)
          "
        >
          {{ $t("Console.btnChatCalling") }}
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="
            changeMessageTab(
              snackbarObject2.type,
              snackbarObject2.fromDriverId,
              snackbarObject2.toDriverId,
              snackbarObject2.toOrderId,
              ''
            ),
              (snackbar2 = false)
          "
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="snackbar2 = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Snackbar o úspešnosti volania na API -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <!-- Snackbar v strede obrazovky, napr. o dlho neschvalenych worklog rquestoch -->
    <v-snackbar
      v-model="snackbarCentered.show"
      :timeout="snackbarCentered.timeout"
      centered
      value="true"
      :color="snackbarCentered.color"
    >
      {{ snackbarCentered.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="snackbarCentered.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- HELP text -->
    <v-dialog
      v-model="helpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="helpDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Help</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <h2 class="my-2">Stavy objednávok</h2>
                <p>
                  Objednávky majú niekoľko stavov, ktoré sa zobrazujú v zozname
                  objednávok a v detailu objednávky. Stavy objednávok sú:
                </p>
                <!-- Zoznam stavov objednavok aj s pouzitymi farbami:  NEW, PLANNED, PENDING, ACCEPTED, WAITING, IN_PROGRESS, FINISHED, CHARGED, SETTLED  -->
                <table>
                  <tr>
                    <td :class="getStatusColor('NEW')">
                      &nbsp;
                      <v-icon>mdi-circle-outline</v-icon>
                      &nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "NEW") }}</b> | Zrušená
                      ><b> {{ $t("orderStatus.CANCELLED_" + "NEW") }}</b> <br />
                      {{ $t("orderStatus_help." + "NEW") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('PLANNED')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon> &nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "PLANNED") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "PLANNED") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "PLANNED") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('PENDING')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "PENDING") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "PENDING") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "PENDING") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('ACCEPTED')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "ACCEPTED") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "ACCEPTED") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "ACCEPTED") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('WAITING')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "WAITING") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "WAITING") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "WAITING") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('IN_PROGRESS')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "IN_PROGRESS") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "IN_PROGRESS") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "IN_PROGRESS") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('FINISHED')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "FINISHED") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "FINISHED") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "FINISHED") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('CHARGED')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "CHARGED") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "CHARGED") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "CHARGED") }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="getStatusColor('SETTLED')">
                      &nbsp; <v-icon>mdi-circle-outline</v-icon>&nbsp;
                    </td>
                    <td>
                      <b> {{ $t("orderStatusSimple." + "SETTLED") }}</b> |
                      Zrušená ><b>
                        {{ $t("orderStatus.CANCELLED_" + "SETTLED") }}</b
                      >
                      <br />
                      {{ $t("orderStatus_help." + "SETTLED") }}
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import GoogleMap from "../components/ConsoleGoogleMap.vue";
//import GoogleMap from "../components/ConsoleOsmMap.vue";
import OrderListPanel from "../components/OrderListPanel";
import OrderNewPanel from "../components/OrderNewPanel";
import OrderDetailPanel from "../components/OrderDetailPanel";
import OrderMessagesPanel from "../components/OrderMessagesPanel";
import { messageTypeColor, messageFromToTypeColor } from "../enums/ConsoleEnum";
import { StatusColor } from "../enums/OrderEnum";

export default {
  name: "App",
  components: {
    GoogleMap,
    OrderListPanel,
    OrderNewPanel,
    OrderDetailPanel,
    OrderMessagesPanel,
  },
  data() {
    return {
      fetchRequests: false,
      tab: null,
      helpDialog: false,
      showPanel: true,
      showInfoPanel: true,
      driverSpeed: false,
      //Stavy pre help dialog
      StatusColor,
      refreshTimerOperational: null,
      refreshTimerMessages: null,
      //console log
      consoleLog: [],
      dispatcherId: null,
      taxiserviceId: null,
      module: moduleDefaultSettings,

      //Setting
      isDark: false,
      switchDarkMapModel: false,
      playNotificationSound: true,
      playNotificationSoundFile: "got-it-done.mp3",
      ignoreSystemMessages: false,
      outbandPrefix: "",

      pathDriver: { text: "Posledných 5 min.", value: "INPROGRESS05MIN" },
      iconDriver: { text: "Špendlík", value: "PIN" },
      iconDriverLabel: { text: "Interné vozidla", value: "VEHICLEINTERNAL" },
      orderMarker: { text: "Špendlík", value: "PIN" },
      //Prepinanie Automatu
      automatDisableDialog: false,
      automatEnableDialog: false,
      //Prepinanie telefonnej ustredne
      pbxDialog: false,

      //Osetrenie vypadku servera
      serverDown: false,
      serverDownRequestCount: 0,

      operationalStatus: {
        //Sumare objednavok
        counterPENDING: 0,
        counterACCEPTED: 0,
        counterWAITING: 0,
        counterIN_PROGRESS: 0,
        counterFINISHED: 0,
        sumPerformedOrders: 0,
        sumActiveOrders: 0,
        minutesPENDING: 0,
        //Sumare vodičov
        counterON_BREAK: 0,
        counterONLINE: 0,
        counterOnlineFREE: 0,
        counterOnlineACCEPTED: 0,
        counterOnlineWAITING: 0,
        counterOnlineIN_PROGRESS: 0,
        counterOnlineFINISHED: 0,
        counterOnlineOTHER: 0,
        counterDriversMoreThanOneOrder: 0,
        //vytazenost taxisluzby
        utilizationRatio: 100,
        sumBusyDrivers: 0,
        sumActiveDrivers: 1,
        //Index zaťaženia taxislužby
        loadRatio: 0,
        //Index Okamzitej dostupnosti
        immediateAvailabilityRatio: 0,
      },

      //Zoznam stanovist
      itemsLocalityStand: [],
      countDriversInStand: 0,
      listOfDriversPerStand: [],
      showListOfDriversPerStand: false,

      //Zoznam požiadaviek na ukončenie práce a prestávky
      workShiftRequestListDialog: false,
      worklogRequestList: [],
      //pocitadlo, kolko krat sa nacita z api kazdy worklog request
      worklogRequestListCounter: [],
      //pocitadlo, kolko krat sa nacita z api kazdy worklog request aby sa objavila notifikacia, ze je poziadavka neodsuhlasena
      worklogRequestTooLongLimit: 10,
      //request data pre zobrazenie schvalovanych pracovnych zmien
      getWorklogRequestItemsData: {
        onlyActive: true,
      },

      //All operational data
      getAllItemsData: {
        showOnlyActiveOrder: true,
        limitOrders: 50,
        countDriverActiveOrders: true,
        messageMinDatetime: null,
        limitMessages: 50,
        showWorklogRequests: true,
      },
      passOrdersToList: null,
      passOrdersOnMap: null,
      passDriversOnMap: null,
      passMessagesToList: null,

      passExtension: null,
      passOrderId: null,
      passOrderTrigger: 0,
      passDriverId: null,
      passDriverTrigger: 0,
      passMapBounds: null,
      passOrderIdFromDetailToMessage: null,
      passMessageTrigger: 0,
      progressStatus: "ok",

      passBoardAddressOnMap: null,
      passDestinationAddressOnMap: null,
      passTransit1AddressOnMap: null,
      passTransit2AddressOnMap: null,
      passTransit3AddressOnMap: null,
      passTransit4AddressOnMap: null,
      //ID vodica, aby sa vycentrovala mapa na jeho polohu
      passFindDriverIdOnMap: null,
      passFindDriverIdOnMapTrigger: 0,
      //Poloha, aby sa vycentrovala mapa na pozadovane miesto
      passFindPositionCoordinatesOnMap: [],

      passMarkerSettedOnMapToNew: null,
      //Messages
      messageTypeColor,
      messageFromToTypeColor,
      changeMessageTabAndSendMessageFromNotification: {
        messageType: "SYSTEM",
        fromDriverId: "",
        toDriverId: "",
        orderId: "",
        messageText: "",
      },
      //premenne pre ALL messages
      allMessages: [],
      getAllMessagesData: {
        limit: 50,
      },
      lastSnackbarNotification: null,
      //snackbar vseobecny pre API
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 3000,
      },
      //snackbar pre upozornenia ktore musi zavriet pouzivatel
      snackbarCentered: {
        show: false,
        message: "OK",
        color: "warning",
        timeout: 3000,
      },

      //snackbar pre messages
      snackbar1: false,
      snackbarObject1: {
        text: "",
        timeout: 8000,
        color: "orange darken-3",
        type: "SYSTEM",
        icon: "info",
        title: "",
        toOrderId: "",
        fromDriverId: "",
        toDriverId: "",
      },
      snackbar2: false,
      snackbarObject2: {
        text: "",
        timeout: 7000,
        color: "green darken-3",
        type: "SYSTEM",
        icon: "info",
        title: "",
        toOrderId: "",
        fromDriverId: "",
        toDriverId: "",
      },
    };
  },

  created() {
    //switch medzi axios (false) a fetch (true)
    if (localStorage.getItem("fetchRequests") != null) {
      this.fetchRequests =
        localStorage.getItem("fetchRequests").toLowerCase() == "true";
    }
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    //console.log("module", this.module);
    //https://stackoverflow.com/questions/19846078/how-to-read-from-chromes-console-in-javascript
    //console log, namiesto do devkonzoly zapisuje do premennej
    var log2Array = "none"; //none, logAndError, log, error
    if (log2Array == "logAndError" || log2Array == "log") {
      let current_log = console.log;
      console.log = (msg) => {
        if (msg !== undefined)
          this.consoleLog.push({
            taxiserviceId: this.taxiserviceId,
            dispatcherId: this.dispatcherId,
            type: "log",
            datetime: new Date().toISOString(),
            value: msg,
          });
        current_log.apply(null, arguments);
      };
    }
    if (log2Array == "logAndError" || log2Array == "error") {
      let current_error = console.error;
      console.error = (msg) => {
        if (msg !== undefined)
          this.consoleLog.push({
            taxiserviceId: this.taxiserviceId,
            dispatcherId: this.dispatcherId,
            type: "error",
            datetime: new Date().toISOString(),
            value: msg,
          });
        current_error.apply(null, arguments);
      };
    }
  },

  mounted() {
    this.testTime();
    //Nacitavanie zoznamov objednavok, polohy vodicov
    this.getAllItems();

    this.refreshTimerOperational = setInterval(() => {
      this.getAllItems();
    }, 10000);
    //get this.taxiserviceId again with delay 2000ms
    setTimeout(() => {
      this.getTaxiserviceId();
    }, 2000);
    //pouzit default cas pre notifikacie aktualny datetime, aby nevyrollovalo notifikacie pre vsetky spravy
    this.lastSnackbarNotification = new Date();
    //console.log("lastSanckbarNoti: ", this.lastSnackbarNotification);
    //Nacitavanie zoznamu sprav
    /*this.getAllMessages();
    this.refreshTimerMessages = setInterval(() => {
      this.getAllMessages();
    }, 10000);*/
    //set pathDriver.value from cookie
    this.pathDriver.value =
      this.getCookie("pathDriverSettings") || "INPROGRESS05MIN";
    //set iconDriver.value from cookie or default
    this.iconDriver.value = this.getCookie("iconDriverSettings") || "PIN";
    this.iconDriverLabel.value =
      this.getCookie("iconDriverLabelSettings") || "VEHICLEINTERNAL";
    this.orderMarker.value = this.getCookie("orderMarkerSettings") || "PIN";
    this.outbandPrefix = this.getCookie("outbandPrefix") || "";
    //set playNotificationSound from cookie or default. Text "true" from cookie is boolean true
    var playNotificationSound = this.getCookie("playNotificationSound");
    if (playNotificationSound && playNotificationSound != null) {
      this.playNotificationSound =
        playNotificationSound.toLowerCase() == "true";
    } else {
      this.playNotificationSound = true;
    }
    var playNotificationSoundFile = this.getCookie("playNotificationSoundFile");
    if (playNotificationSoundFile && playNotificationSoundFile != null) {
      this.playNotificationSoundFile = playNotificationSoundFile;
    }
    var switchDarkMapModel = this.getCookie("switchDarkMapModel");
    if (switchDarkMapModel && switchDarkMapModel != null) {
      this.switchDarkMapModel = switchDarkMapModel.toLowerCase() == "true";
    } else {
      this.switchDarkMapModel = false;
    }
    var showInfoPanel = this.getCookie("showInfoPanel");
    if (showInfoPanel && showInfoPanel != null) {
      this.showInfoPanel = showInfoPanel.toLowerCase() == "true";
    } else {
      this.showInfoPanel = true;
    }
    var driverSpeed = this.getCookie("driverSpeed");
    if (driverSpeed && driverSpeed != null) {
      this.driverSpeed = driverSpeed.toLowerCase() == "true";
    } else {
      this.driverSpeed = false;
    }

    //Nacitanie ulozenej temy
    const theme = localStorage.getItem("dark_theme");
    //const bodyElement = document.getElementsByTagName("body")[0];
    if (theme) {
      if (theme == "true") {
        this.isDark = true;
        this.$vuetify.theme.dark = true;
        //bodyElement.classList = "darkScrollbar";
      } else {
        this.isDark = false;
        this.$vuetify.theme.dark = false;
        //bodyElement.classList = "";
      }
    }
    //Nacitanie stanovišť
    setTimeout(() => {
      this.getStands();
    }, 3000);
    //Random worklogRequestTooLongLimit aby sa každému dispečerovi zobrazila pripomienka po 2min ale inom case
    this.worklogRequestTooLongLimit = Math.floor(Math.random() * 10) + 20;
    console.log(
      "worklogRequestTooLongLimit: ",
      this.worklogRequestTooLongLimit
    );
  },

  watch: {
    isDark: function (val) {
      this.$vuetify.theme.dark = val;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    //selectedOrderIdMessage: function () {
    //console.log("SOM Z ORDERGROUPPANEL ", this.passSelectedOrderId);
    //this.selectedOrderId = this.passSelectedOrderId;
    // this.tab = "tab-detail";
    //},
  },
  methods: {
    getTaxiserviceId() {
      if (this.taxiserviceId == null) {
        this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
      }
    },
    testTime() {
      //Overenie, ci ma PC nastaveny spravny cas oproti serveru
      const url = `/dispatching/server-time`;
      //console.log("fetchRequests: ", this.fetchRequests);
      if (this.fetchRequests == false) {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + url)
          .then((response) => {
            this.testTimeThen(response.data);
          })
          .catch((e) => {
            console.error("UNAVAILABLE TIME CHECK " + e);
          });
      } else {
        const options = {
          method: "GET",
        };
        this.$fetchRequest(url, options)
          .then((response) => {
            if (!response.ok) {
              // Ak server vráti chybový status, môžete spracovať chybu tu
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((response) => {
            this.testTimeThen(response);
          })
          .catch((error) => {
            console.error("UNAVAILABLE TIME CHECK " + error);
          });
      }
    },
    testTimeThen(response) {
      //Overenie, ci ma PC nastaveny spravny cas oproti serveru
      var serverTime = new Date(response);
      var pcTime = new Date();

      if ((serverTime - pcTime) / 1000 > 300) {
        //na sekundy - 5min tolerancia
        alert(this.$t("Console.msgTestTimeProblem"));
        console.log(
          "serverTime: " +
            serverTime.toISOString() +
            " / " +
            response +
            " pcTime: " +
            pcTime.toISOString() +
            " / " +
            pcTime +
            " compare: " +
            (serverTime - pcTime)
        );
      } else if ((pcTime - serverTime) / 1000 > 300) {
        //na sekundy - 5min tolerancia
        alert(this.$t("Console.msgTestTimeProblem"));
        console.log(
          "serverTime: " +
            serverTime.toISOString() +
            " / " +
            response +
            " pcTime: " +
            pcTime.toISOString() +
            " / " +
            pcTime +
            " compare: " +
            (serverTime - pcTime)
        );
      }
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getAllItems() {
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      //Pokus o usporu hodnot ktore sa vracaju pri spravach. Bude treba prerobit aby sa vracali len spravy novsie ako je posledna,
      //teda posielat na server datetime poslednej spravy a idealne rovno v requeste so vsetkymi operacnymi spravami
      //IF allMessages length is 0, then set getAllMessagesData.limit: 50, else 5
      if (this.allMessages.length == 0) {
        this.getAllItemsData.limitMessages = 50;
      } else {
        this.getAllItemsData.limitMessages = 5;
      }
      this.progressStatus = "start";
      const url = `/dispatching/operationalData`;
      if (this.fetchRequests == false) {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + url, {
            params: this.getAllItemsData,
            timeout: 10000, // Timeout v milisekundách (10000ms = 10 sekúnd)
          })
          .then((response) => {
            this.getAllItemsThen(response.data);
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            this.progressStatus = "error";
            console.error("UNAVAILABLE GET OPERATIONAL DATA " + e);
            //zobrazovanie udajov pri vypadku servera
            if (this.serverDown == false) {
              //zapnutie flagu, ze vypadol server
              this.serverDownRequestCount++;
              this.serverDown = true;
              this.loadOrderList();
            }
          });
      } else {
        var paramsString = Object.entries(this.getAllItemsData)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");
        const options = {
          method: "GET",
        };
        this.$fetchRequest(url + "?" + paramsString, options)
          .then((response) => {
            if (!response.ok) {
              // Ak server vráti chybový status, môžete spracovať chybu tu
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((responseData) => {
            this.getAllItemsThen(responseData);
          })
          .catch((error) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            this.progressStatus = "error";
            console.error("UNAVAILABLE GET OPERATIONAL DATA " + error);
            //zobrazovanie udajov pri vypadku servera
            if (this.serverDown == false) {
              //zapnutie flagu, ze vypadol server
              this.serverDownRequestCount++;
              this.serverDown = true;
              this.loadOrderList();
            }
          });
      }
    },
    getAllItemsThen(response) {
      //this.animateProgressBar("ok");
      this.passOrdersToList = response.orderList;
      if (this.orderMarker.value != "NEVER") {
        this.passOrdersOnMap = response.orderList;
      } else {
        this.passOrdersOnMap = [];
      }
      var passDriversOnMap = response.driverList;
      var worklogRequestListCounter = this.worklogRequestListCounter;
      var worklogRequestTooLongLimit = this.worklogRequestTooLongLimit;
      if (response.worklogRequestList) {
        if (response.worklogRequestList.length > 0) {
          var worklogRequestList = response.worklogRequestList;
          for (var i = 0; i < worklogRequestList.length; i++) {
            var worklogRequest = worklogRequestList[i];
            //find in passDriversOnMap driver user_driver_id and add to it worklogRequestStatus and worklogRequestType
            for (var j = 0; j < passDriversOnMap.length; j++) {
              var driver = passDriversOnMap[j];
              if (driver.id == worklogRequest.driverId) {
                driver.worklogRequestStatus = worklogRequest.status;
                driver.worklogRequestType = worklogRequest.type;
              }
            }
            //count worklogRequestListCounter in array by id if status=NEW
            if (worklogRequest.status == "NEW") {
              var found = false;
              for (var k = 0; k < worklogRequestListCounter.length; k++) {
                if (worklogRequestListCounter[k].id == worklogRequest.id) {
                  found = true;
                  worklogRequestListCounter[k].count++;
                  if (
                    worklogRequestListCounter[k].count ==
                    worklogRequestTooLongLimit
                  ) {
                    this.snackbarCentered.show = "true";
                    this.snackbarCentered.message = this.$t(
                      "Console.msgWorklogRequestNotConfirmedTooLong"
                    );
                    this.snackbarCentered.color = "warning";
                    this.snackbarCentered.timeout = 9000;
                  }
                }
              }
              if (found == false) {
                worklogRequestListCounter.push({
                  id: worklogRequest.id,
                  count: 1,
                });
              }
            } else {
              //remove from worklogRequestListCounter
              for (var k = 0; k < worklogRequestListCounter.length; k++) {
                if (worklogRequestListCounter[k].id == worklogRequest.id) {
                  worklogRequestListCounter.splice(k, 1);
                }
              }
            }
          }
        }
      } else {
        worklogRequestListCounter = [];
      }
      this.passDriversOnMap = passDriversOnMap;
      /*var allMessagesTemp = response.messageList;
          if (this.allMessages > 0 && allMessagesTemp.length > 0) {
            //vsetky spravy sa poslu do komponentu OrderMessagesPanel
            this.allMessages = allMessagesTemp;
            this.processAllMessages(allMessagesTemp);
          } else {
            this.getAllMessages();
          }*/
      var allMessagesTemp = response.messageList;

      if (allMessagesTemp.length > 0) {
        //Zisit cas najnovsej notifikacie u sprav, ktore sa prvy krat nacitaju
        if (this.allMessages.length == 0) {
          //1. krat, ked este nemame historicke udaje, tak skontrolovat vsetky spravy a nastavit datumu poslednej notifikacie
          var maxNotificationDatetime = new Date("1999-01-01T01:01:01.49561Z");
          var messageDate;
          allMessagesTemp.forEach(function (item, index) {
            //Vsetky novsie od poslednej notifikacie zobrazit
            messageDate = new Date(item.createdAt);
            //zistit najnovsi datum spravy, ten bude referencny pri dalsom requeste. Podla toho sa zobrazuju snackbary a aktualizuju zoznamy.
            if (messageDate > maxNotificationDatetime) {
              maxNotificationDatetime = messageDate;
            }
          });
          //Nastavit novy cas poslednej notifikacie
          this.lastSnackbarNotification = maxNotificationDatetime;
          this.getAllItemsData.messageMinDatetime = maxNotificationDatetime;
        }
        //vsetky spravy sa poslu do komponentu OrderMessagesPanel
        this.allMessages = allMessagesTemp;
        //Spravy sa tie pouziju na analyzu, ci sa ma zobrazi snackbar notifikacia
        this.processAllMessages(allMessagesTemp);
      }

      this.progressStatus = "ok";
      this.countStatistic(response);
      this.standStatistic(response);
      //lokalne ulozit udaje o objednavkach, pre pripad vypadku servera
      this.storeOrderList(response.orderList);
      //ak uz vypadok skoncil, tak zobrazit informacie ze skoncil vypadok
      if (this.serverDown == true) {
        this.serverDownRequestCount = 0;
        this.serverDown = false;
        this.onlineOrderList();
      }
    },

    getAllMessages() {
      //Pokus o usporu hodnot ktore sa vracaju pri spravach. Bude treba prerobit aby sa vracali len spravy novsie ako je posledna,
      //teda posielat na server datetime poslednej spravy a idealne rovno v requeste so vsetkymi operacnymi spravami
      //IF allMessages length is 0, then set getAllMessagesData.limit: 50, else 5
      if (this.allMessages.length == 0) {
        this.getAllMessagesData.limit = 50;
      } else {
        this.getAllMessagesData.limit = 5;
      }
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/message/all`, {
          params: this.getAllMessagesData,
        })
        .then((response) => {
          //this.animateProgressBar("ok");
          //Ked nepridu ziadne nove spravy, nic nerobit.
          var allMessagesTemp = response.data;
          //console.log("Console.vue - allMessagesTemp.length",allMessagesTemp.length);
          if (allMessagesTemp.length > 0) {
            //console.log("Console.vue - this.allMessages.length",this.allMessages.length);
            //Zisit cas najnovsej notifikacie u sprav, ktore sa prvy krat nacitaju
            if (this.allMessages.length == 0) {
              //1. krat, ked este nemame historicke udaje, tak skontrolovat vsetky spravy a nastavit datumu poslednej notifikacie
              var maxNotificationDatetime = new Date(
                "1999-01-01T01:01:01.49561Z"
              );
              var messageDate;
              allMessagesTemp.forEach(function (item, index) {
                //console.log("createdAt: ", item.createdAt, "vs. notificationDatetime: ", notificationDatetime);
                //Vsetky novsie od poslednej notifikacie zobrazit
                messageDate = new Date(item.createdAt);
                //console.log("messageDate: ", messageDate);
                //zistit najnovsi datum spravy, ten bude referencny pri dalsom requeste. Podla toho sa zobrazuju snackbary a aktualizuju zoznamy.
                if (messageDate > maxNotificationDatetime) {
                  maxNotificationDatetime = messageDate;
                  //console.log("Console.vue - maxNotificationDatetime: ",maxNotificationDatetime);
                }
              });
              //Nastavit novy cas poslednej notifikacie
              this.lastSnackbarNotification = maxNotificationDatetime;
              this.getAllItemsData.messageMinDatetime = maxNotificationDatetime;
            }

            //vsetky spravy sa poslu do komponentu OrderMessagesPanel
            this.allMessages = allMessagesTemp;
            //Spravy sa tie pouziju na analyzu, ci sa ma zobrazi snackbar notifikacia
            this.processAllMessages(allMessagesTemp);
          }
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          console.error("UNAVAILABLE GET MESSAGE DATA " + e);
        });
    },
    processAllMessages(allMessagesTemp) {
      var playNotificationSoundFile = this.playNotificationSoundFile;
      var ignoreSystemMessages = this.ignoreSystemMessages;
      //ID prihlaseneho dispecera
      var dispatcherId = this.dispatcherId;
      //console.log("dispatcherId", dispatcherId);
      //Pri spusteni ked su prazdne spravy, nacitat predvoleny pocet.
      //console.log("processAllMessages");
      // if (this.allMessages.length === 0) {
      //   console.log("this.allMessages.length", this.allMessages.length);
      //this.allMessages = response.data;
      //}
      //var allMessagesTemp = this.allMessages;
      //var allMessagesTemp = response.data;
      var newMessages = [];
      var messageDate = null;
      var notificationDatetime = this.lastSnackbarNotification;
      var maxNotificationDatetime = this.lastSnackbarNotification;
      var ignoreMessage = false;
      //skotrolovat vsetky spravy a ak sa medzi nimi nachadza nejaka nova podla datumu poslednej notifikacie, tak zobrazit snackbar
      allMessagesTemp.forEach(function (item, index) {
        ignoreMessage = false;
        //console.log("createdAt: ", item.createdAt, "vs. notificationDatetime: ", notificationDatetime);
        //Vsetky novsie od poslednej notifikacie zobrazit
        messageDate = new Date(item.createdAt);
        //console.log("messageDate: ", messageDate);
        if (messageDate > notificationDatetime) {
          //ignorovat systemove spravy ak je to zapnute
          if (ignoreSystemMessages == true && item.type == "SYSTEM") {
            ignoreMessage = true;
          }
          //Nezobrazovat notifikaciu k vlastnej sprave
          if (
            item.fromType == "DISPATCHER" &&
            item.fromDispatcherId == dispatcherId
          ) {
            ignoreMessage = true;
          }
          //odlozit si iba nove spravy, neskor ich pridame naraz do celkoveho zoznamu na vyrenderovanie
          if (ignoreMessage == false) {
            newMessages.push(item);
          }
          //zistit najnovsi datum spravy, ten bude referencny pri dalsom requeste. Podla toho sa zobrazuju snackbary a aktualizuju zoznamy.
          if (messageDate > maxNotificationDatetime) {
            maxNotificationDatetime = messageDate;
          }
        }
      });
      //Doplnenie sprav do celkoveho zoznamu, aby sa vyrenderovali. Manualne, max 2 sprav (viac by nemalo prist pocas 10 sekund).
      if (Object.keys(newMessages).length > 0) {
        //Zobrazenie snackbar 1
        var snackTitle = "";
        var snackIcon = "info";
        if (newMessages[0].fromType == "DISPATCHER") {
          snackIcon = "mdi-face-man-outline";
          snackTitle =
            (newMessages[0].fromDispatcherFirstName
              ? newMessages[0].fromDispatcherFirstName
              : "") +
            " " +
            (newMessages[0].fromDispatcherLastName
              ? newMessages[0].fromDispatcherLastName
              : "");
        } else if (newMessages[0].fromType == "DRIVER") {
          snackIcon = "mdi-account-tie-outline";
          snackTitle =
            (newMessages[0].fromDriverFirstName
              ? newMessages[0].fromDriverFirstName
              : "") +
            " " +
            (newMessages[0].fromDriverLastName
              ? newMessages[0].fromDriverLastName
              : "") +
            ": ";
        } else if (newMessages[0].fromType == "CLIENT") {
          snackIcon = "mdi-human-greeting-variant";
          snackTitle =
            (newMessages[0].fromClientNickname
              ? newMessages[0].fromClientNickname
              : "") + ": ";
        } else if (newMessages[0].fromType == "SYSTEM") {
          snackIcon = "mdi-robot-outline";
          snackTitle = "SYSTEM";
        } else {
          snackIcon = "mdi-alien-outline";
          snackTitle = "Ufon";
        }
        this.snackbarObject1.title = snackTitle;
        this.snackbarObject1.icon = snackIcon;
        this.snackbarObject1.text = newMessages[0].message;
        this.snackbarObject1.type = newMessages[0].type;
        this.snackbarObject1.toOrderId = newMessages[0].toOrderId;
        this.snackbarObject1.fromDriverId = newMessages[0].fromDriverId;
        this.snackbarObject1.toDriverId = newMessages[0].toDriverId;
        this.snackbar1 = true;
        //play sound
        if (this.playNotificationSound == true) {
          //zakladny zvuk notifikacie
          //var audioSrc = require("../assets/media/got-it-done.mp3");
          var audio = new Audio();
          audio.src = require("../assets/media/" + playNotificationSoundFile);
          audio.play();
        }
      }
      if (Object.keys(newMessages).length > 1) {
        //Zobrazenie snackbar 2
        var snackTitle = "";
        var snackIcon = "info";
        if (newMessages[1].fromType == "DISPATCHER") {
          snackIcon = "mdi-face-man-outline";
          snackTitle =
            (newMessages[1].fromDispatcherFirstName
              ? newMessages[1].fromDispatcherFirstName
              : "") +
            " " +
            (newMessages[1].fromDispatcherLastName
              ? newMessages[1].fromDispatcherLastName
              : "");
        } else if (newMessages[1].fromType == "DRIVER") {
          snackIcon = "mdi-account-tie-outline";
          snackTitle =
            (newMessages[1].fromDriverFirstName
              ? newMessages[1].fromDriverFirstName
              : "") +
            " " +
            (newMessages[1].fromDriverLastName
              ? newMessages[1].fromDriverLastName
              : "") +
            ": ";
        } else if (newMessages[1].fromType == "CLIENT") {
          snackIcon = "mdi-human-greeting-variant";
          snackTitle =
            (newMessages[1].fromClientNickname
              ? newMessages[1].fromClientNickname
              : "") + ": ";
        } else if (newMessages[1].fromType == "SYSTEM") {
          snackIcon = "mdi-robot-outline";
          snackTitle = "SYSTEM";
        } else {
          snackIcon = "mdi-alien-outline";
          snackTitle = "Ufon";
        }
        this.snackbarObject2.title = snackTitle;
        this.snackbarObject2.icon = snackIcon;
        this.snackbarObject2.text = newMessages[1].message;
        this.snackbarObject2.type = newMessages[1].type;
        this.snackbarObject2.toOrderId = newMessages[1].toOrderId;
        this.snackbarObject2.fromDriverId = newMessages[1].fromDriverId;
        this.snackbarObject2.toDriverId = newMessages[1].toDriverId;
        this.snackbar2 = true;
      }
      //Nastavit novy cas poslednej notifikacie
      this.lastSnackbarNotification = maxNotificationDatetime;
    },
    countStatistic(inputData) {
      var orderList = inputData.orderList;
      var driverList = inputData.driverList;
      //Sumare objednavok
      var counterPREREGISTERED = 0;
      var counterTRANSFERED = 0;
      var counterNEW = 0;
      var counterPLANNED = 0;
      var counterPENDING = 0;
      var minutesPENDING = 0;
      var counterPENDINGtoAccept = 0;
      var minutesPENDINGtoAccept = 0;
      var counterACCEPTED = 0;
      var counterWAITING = 0;
      var counterIN_PROGRESS = 0;
      var counterFINISHED = 0;
      var counterCHARGED = 0;
      var counterSETTLED = 0;
      var counterCANCELLED = 0;
      var sumPerformedOrders = 0; //Priradene k vodicom
      var sumActiveOrders = 0; // Priradene k vodicom + Cakajuce na priradenie
      //Sumare vodičov
      var counterOFFLINE = 0;
      var counterON_BREAK = 0;
      var counterONLINE = 0;
      var counterOnlineFREE = 0;
      var counterOnlineACCEPTED = 0;
      var counterOnlineWAITING = 0;
      var counterOnlineIN_PROGRESS = 0;
      var counterOnlineFINISHED = 0;
      var counterOnlineOTHER = 0;
      var counterDriversMoreThanOneOrder = 0;
      //vytazenost taxisluzby
      var utilizationRatio = 0;
      var utilizationStatus = "OK";
      var sumBusyDrivers = 0; //Vodici ktori maju priradenu objednavku
      var sumActiveDrivers = 1; //Vodici ktori su na pracovnej zmene a nie su na prestavke
      //Index zaťaženia taxislužby
      var loadRatio = 0; //Pocet vsetkych aktivnych objednavok k poctu aktivnych vodicov
      var tempOperationalStatus = {};
      //Index okamžitej dostupnosti
      var immediateAvailabilityRatio = 0;
      //pocitanie casu objednavok
      var requestedTime;
      var now = moment();
      var duration;
      var seconds;
      var minutes;
      orderList.forEach(function (item, index) {
        if (item.status == "PREREGISTERED") {
          ++counterPREREGISTERED;
        } else if (item.status == "TRANSFERED") {
          ++counterTRANSFERED;
        } else if (item.status == "NEW") {
          ++counterNEW;
        } else if (item.status == "PLANNED") {
          ++counterPLANNED;
        } else if (item.status == "PENDING") {
          ++counterPENDING;
          //vypocet casu pridelovania objednavky
          if (item.type == "INSTANT") {
            requestedTime = moment(item.requestedTime);
            duration = moment.duration(now.diff(requestedTime));
            seconds = duration.asSeconds();
            //konvertuj seconds na minúty a zaokrúhli na celé číslo
            minutes = Math.round(seconds / 60);
          } else if (item.type == "TIME") {
            //ak je order.type == "TIME", vypočítaj koľko sekúnd je od požadovaného času orderu (requestedTime) minus presendTime (v minútach) so zohľadnením lokálneho času (knižnica moment.js)
            var requestedTime = moment(item.requestedTime);
            //presendTime je v minútach, preto ho musíme prekonvertovať na sekundy
            var presendTime = item.presendTime * 60;
            requestedTime = requestedTime.subtract(presendTime, "seconds");
            var duration = moment.duration(now.diff(requestedTime));
            var seconds = duration.asSeconds();
            //konvertuj seconds na minúty a zaokrúhli na celé číslo
            minutes = Math.round(seconds / 60);
          } else {
            minutes = 0;
          }
          minutesPENDING += minutes;
        } else if (item.status == "ACCEPTED") {
          ++counterACCEPTED;
        } else if (item.status == "WAITING") {
          ++counterWAITING;
        } else if (item.status == "IN_PROGRESS") {
          ++counterIN_PROGRESS;
        } else if (item.status == "FINISHED") {
          ++counterFINISHED;
        } else if (item.status == "CHARGED") {
          ++counterCHARGED;
        } else if (item.status == "SETTLED") {
          ++counterSETTLED;
        } else if (
          item.status == "CANCELLED_PLANNED" ||
          item.status == "CANCELLED_PENDING" ||
          item.status == "CANCELLED_ACCEPTED" ||
          item.status == "CANCELLED_WAITING" ||
          item.status == "CANCELLED_IN_PROGRESS" ||
          item.status == "CANCELLED_FINISHED" ||
          item.status == "CANCELLED_CHARGED" ||
          item.status == "CANCELLED_SETTLED"
        ) {
          ++counterCANCELLED;
        }
        if (
          item.status == "ACCEPTED" ||
          item.status == "WAITING" ||
          item.status == "IN_PROGRESS" ||
          item.status == "FINISHED"
        ) {
          ++counterPENDINGtoAccept;
          //minutesPendingToAccept is minutes between requestedTime and acceptedTime
          requestedTime = moment(item.requestedTime);
          var acceptedTime = moment(item.acceptedTime);
          duration = moment.duration(acceptedTime.diff(requestedTime));
          seconds = duration.asSeconds();
          //konvertuj seconds na minúty a zaokrúhli na celé číslo
          minutes = Math.round(seconds / 60);
          minutesPENDINGtoAccept += minutes;
        }
      });
      tempOperationalStatus.counterPENDING = counterPENDING;
      tempOperationalStatus.counterACCEPTED = counterACCEPTED;
      tempOperationalStatus.counterWAITING = counterWAITING;
      tempOperationalStatus.counterIN_PROGRESS = counterIN_PROGRESS;
      tempOperationalStatus.counterFINISHED = counterFINISHED;
      tempOperationalStatus.minutesPENDING = minutesPENDING;
      //console.log(        "PLANNED: ",        counterPLANNED,        " | PENDING: ",        counterPENDING,        " | ACCEPTED: ",        counterACCEPTED,        " | WAITING: ",        counterWAITING,        " | IN_PROGRESS: ",        counterIN_PROGRESS,        " | FINISHED: ",        counterFINISHED,        " | CANCELLED: ",        counterCANCELLED      );
      /* console.log("Cas a pocet v Pending", minutesPENDING, counterPENDING);
      console.log(
        "Priemerny cas v Pending",
        (minutesPENDING / counterPENDING).toFixed(1)
      );
      console.log(
        "Cas a pocet v PendingToAccept",
        minutesPENDINGtoAccept,
        counterPENDINGtoAccept
      );
      console.log(
        "Priemerny cas v PendingToAccept",
        (minutesPENDINGtoAccept / counterPENDINGtoAccept).toFixed(1)
      ); */

      driverList.forEach(function (item, index) {
        if (item.workStatus == "OFFLINE") {
          ++counterOFFLINE;
        } else if (item.workStatus == "ON_BREAK") {
          ++counterON_BREAK;
        } else if (item.workStatus == "ONLINE") {
          if (item.orderActual != null) {
            if (item.orderActual.status == "ACCEPTED") {
              ++counterOnlineACCEPTED;
            } else if (item.orderActual.status == "WAITING") {
              ++counterOnlineWAITING;
            } else if (item.orderActual.status == "IN_PROGRESS") {
              ++counterOnlineIN_PROGRESS;
            } else if (item.orderActual.status == "FINISHED") {
              ++counterOnlineFINISHED;
            } else {
              ++counterOnlineOTHER;
            }
          } else {
            ++counterONLINE;
          }
          //Pocet vodicov čo majú 2 a viac objednavok
          if (item.countActiveOrders) {
            if (item.countActiveOrders > 1) {
              ++counterDriversMoreThanOneOrder;
            }
          }
        }
      });
      counterOnlineFREE = counterONLINE + counterOnlineOTHER;
      tempOperationalStatus.counterON_BREAK = counterON_BREAK;
      tempOperationalStatus.counterOnlineACCEPTED = counterOnlineACCEPTED;
      tempOperationalStatus.counterOnlineWAITING = counterOnlineWAITING;
      tempOperationalStatus.counterOnlineIN_PROGRESS = counterOnlineIN_PROGRESS;
      tempOperationalStatus.counterOnlineFINISHED = counterOnlineFINISHED;
      tempOperationalStatus.counterONLINE = counterONLINE;
      tempOperationalStatus.counterOnlineFREE = counterOnlineFREE;
      tempOperationalStatus.counterOnlineOTHER = counterOnlineOTHER;
      tempOperationalStatus.counterDriversMoreThanOneOrder =
        counterDriversMoreThanOneOrder;
      //console.log(        "OFFLINE: ",        counterOFFLINE,        " | ON_BREAK: ",        counterON_BREAK,        " | ONLINE: ",        counterONLINE,        " | ACCEPTED: ",        counterOnlineACCEPTED,        " | WAITING: ",        counterOnlineWAITING,        " | IN_PROGRESS: ",        counterOnlineIN_PROGRESS,        " | FINISHED: ",        counterOnlineFINISHED      );
      sumBusyDrivers =
        counterOnlineACCEPTED +
        counterOnlineWAITING +
        counterOnlineIN_PROGRESS +
        counterOnlineFINISHED;
      tempOperationalStatus.sumBusyDrivers = sumBusyDrivers;
      sumActiveDrivers = sumBusyDrivers + counterOnlineFREE; //nesmie byt 0, lebo nizsie prebieha delenie tymto cislom
      tempOperationalStatus.sumActiveDrivers = sumActiveDrivers;
      //Vytazenost v %
      utilizationRatio = Math.round(
        sumBusyDrivers / ((sumActiveDrivers > 0 ? sumActiveDrivers : 1) / 100)
      );
      tempOperationalStatus.utilizationRatio = utilizationRatio;
      //console.log("utilizationRatio: ", utilizationRatio);
      sumPerformedOrders =
        counterACCEPTED + counterWAITING + counterIN_PROGRESS + counterFINISHED;
      tempOperationalStatus.sumPerformedOrders = sumPerformedOrders;
      sumActiveOrders = counterPENDING + sumPerformedOrders;
      tempOperationalStatus.sumActiveOrders = sumActiveOrders;
      //Index zaťaženia (load ratio)
      if (sumActiveDrivers > 0) {
        loadRatio = sumActiveOrders / sumActiveDrivers;
        if (loadRatio < 10) {
          loadRatio = Math.round(loadRatio * 100) / 100;
        } else {
          loadRatio = Math.round(loadRatio);
        }
      } else {
        loadRatio = 0;
      }
      tempOperationalStatus.loadRatio = loadRatio;

      //Index okamžitej dostupnosti vozidla (immediate availability ratio)
      immediateAvailabilityRatio =
        counterPENDING / (counterOnlineFREE > 0 ? counterOnlineFREE : 1);
      if (immediateAvailabilityRatio < 10) {
        immediateAvailabilityRatio =
          Math.round(immediateAvailabilityRatio * 100) / 100;
      } else {
        immediateAvailabilityRatio = Math.round(immediateAvailabilityRatio);
      }
      tempOperationalStatus.immediateAvailabilityRatio =
        immediateAvailabilityRatio;

      this.operationalStatus = Object.assign({}, tempOperationalStatus);
      //console.log("operationalStatus: ", this.operationalStatus);
    },

    getStands() {
      var map = this.map;
      const url = `/dispatching/stand`;
      if (this.fetchRequests == false) {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + url)
          .then((response) => {
            this.getStandsThen(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const options = {
          method: "GET",
        };
        this.$fetchRequest(url, options)
          .then((response) => {
            if (!response.ok) {
              // Ak server vráti chybový status, môžete spracovať chybu tu
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((response) => {
            this.getStandsThen(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getStandsThen(response) {
      var map = this.map;
      var itemList = response;
      //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
      if (itemList.constructor.name != "Array") {
        var newItemList = [];
        newItemList.push(itemList);
        itemList = newItemList;
      }
      this.itemsLocalityStand = itemList;
      //zapamatanie si, aby sme nemuseli vzdy nacitavat polohu z DB
      itemList.forEach((item) => {
        if (item.active == true) {
          if (item.defaultStand == true) {
            var defaultStandPosition;
            defaultStandPosition = {
              lat: item.latitude,
              lng: item.longitude,
            };
            this.setCookie(
              "defaultStandPosition",
              JSON.stringify(defaultStandPosition),
              1
            );
          }
        }
      });
    },

    standStatistic(inputData) {
      //get list of stands
      var countDriversInStand = 0;
      var listOfDriversPerStand = [];
      var itemsLocalityStand = this.itemsLocalityStand;
      var tempItemsLocalityStand = [];
      //console.log("itemsLocalityStand: ", itemsLocalityStand);
      var standPosition;
      var standRadius;
      //get list of drivers
      var driverList = inputData.driverList;
      //console.log("driverList: ", driverList);
      var driverActualPosition;
      //Loop driver list
      //driverList.forEach(function (itemDriver, index) {
      driverList.forEach((itemDriver) => {
        //get driver actual position from actualPositon: {latitude: 48.148596, longitude: 17.107748}
        //console.log("itemDriver: ", itemDriver);
        if (itemDriver.actualPosition) {
          driverActualPosition = itemDriver.actualPosition;
          driverActualPosition = {
            latitude: driverActualPosition.latitude,
            longitude: driverActualPosition.longitude,
          };
        }
        //console.log("driverActualPosition: ", driverActualPosition);
        //Loop stand list and in compare, if driver is in stand
        tempItemsLocalityStand = itemsLocalityStand;
        //tempItemsLocalityStand.forEach(function (itemStand, indexStand) {
        tempItemsLocalityStand.forEach((itemStand) => {
          //console.log("itemStand: ", itemStand);
          if (itemStand.active == true) {
            standPosition = {
              latitude: itemStand.latitude,
              longitude: itemStand.longitude,
            };
            standRadius = itemStand.radius;
            var lat1 = driverActualPosition.latitude;
            var lon1 = driverActualPosition.longitude;
            var lat2 = standPosition.latitude;
            var lon2 = standPosition.longitude;
            //check if driverActualPosition of driver is within radius of stand
            var R = 6371; // Radius of the earth in km
            var dLat = (lat2 - lat1) * (Math.PI / 180);
            var dLon = (lon2 - lon1) * (Math.PI / 180);
            var a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1 * (Math.PI / 180)) *
                Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var distance = R * c * 1000; // Distance in m

            if (distance <= standRadius) {
              //console.log("Driver is in stand");
              //add driver to list of drivers in stand
              itemDriver.standId = itemStand.id;
              itemDriver.standName = itemStand.name;
              itemDriver.standInternalName = itemStand.internalName;
              //ak je vodič aktívny a bez objednávky, tak ho pridáme do zoznamu
              if (
                itemDriver.workStatus == "ONLINE" &&
                itemDriver.orderActual == null
              ) {
                listOfDriversPerStand.push(itemDriver);
                //count drivers in stand
                countDriversInStand++;
              }
              if (itemDriver.workStatus == "ON_BREAK") {
                listOfDriversPerStand.push(itemDriver);
                //count drivers in stand
                countDriversInStand++;
              }
            } else {
              //console.log("Driver is not in stand");
            }
          }
        });
      });
      //order listOfDriversPerStand by standId
      listOfDriversPerStand.sort(function (a, b) {
        return a.standId - b.standId;
      });
      this.listOfDriversPerStand = listOfDriversPerStand;
      this.countDriversInStand = countDriversInStand;
      //console.log("listOfDriversPerStand: ", listOfDriversPerStand);
      //console.log("countDriversInStand: ", countDriversInStand);
    },
    //redistributeOperationalData(operationalData) {
    // this.passDriversOnMap = operationalData.driverList;
    //this.passMessagesToList = operationalData.chatList;
    //console.log("Console.vue: ", operationalData);
    //console.log("Console.vue: ", this.passDriversOnMap);
    // },
    actionForSwitchOnlyActiveOrder(value) {
      //console.log("actionForSwitchOnlyActiveOrder: ", value);
      this.getAllItemsData.showOnlyActiveOrder = value;
      this.getAllItems();
    },
    actionForOrderDetailByIdFromList(orderId) {
      this.passOrderId = orderId;
      //increment value of passOrderTrigger to force OrderDetail.vue to reload data
      this.passOrderTrigger = this.passOrderTrigger + 1;
      this.tab = "tab-detail";
      //console.log("Console.vue: ", orderId);
      //console.log("Console.vue: ", this.passOrderId);
    },
    actionForOrderDetailByIdFromMap(orderId) {
      this.passOrderId = orderId;
      //increment value of passOrderTrigger to force OrderDetail.vue to reload data
      this.passOrderTrigger = this.passOrderTrigger + 1;
      this.tab = "tab-detail";
    },
    setDriverToMessage(driverId) {
      var messageObj = {};
      messageObj.messageType = "DISP_DRIVER";
      messageObj.fromDriverId = driverId;
      messageObj.toDriverId = driverId;
      messageObj.orderId = "";
      messageObj.messageText = "";
      this.actionForMessageFromMap(messageObj);
    },
    actionForMessageFromMap(messageObj) {
      this.changeMessageTab(
        messageObj.messageType,
        messageObj.fromDriverId,
        messageObj.toDriverId,
        messageObj.orderId,
        messageObj.messageText
      );
      //increment value of passMessageTrigger to force MessageDetail.vue to reload data
      this.passMessageTrigger = this.passMessageTrigger + 1;
      this.tab = "tab-messages";
    },
    actionForSetDriverToDetailFromMap(driverId) {
      this.passDriverId = driverId;
      //console.log("passDriverId", this.passDriverId);
      //increment value of passDriverTrigger to force update of DriverDetail component
      this.passDriverTrigger = this.passDriverTrigger + 1;
      this.tab = "tab-detail";
    },
    actionForActualMapBounds(boundsObject) {
      this.passMapBounds = boundsObject;
      //console.log("boundsObject", boundsObject);
    },
    actionForOrderDetailByIdFromNew(orderId) {
      this.passOrderId = orderId;
      this.passOrderTrigger = this.passOrderTrigger + 1;
      this.tab = "tab-detail";
      //console.log("Console.vue: ", orderId);
      //console.log("Console.vue: ", this.passOrderId);
    },
    actionForSetExtension(extension) {
      this.passExtension = extension;
      //console.log("Console.vue: ", extension);
      //console.log("Console.vue: ", this.passExtension);
    },
    actionForMessageFromOrderDetail(orderId) {
      this.passOrderIdFromDetailToMessage = orderId;
      this.tab = "tab-messages";
      //console.log("Console.vue: ", orderId);
      //console.log("Console.vue - passOrderIdFromDetailToMessage: ", this.passOrderIdFromDetailToMessage);
    },
    actionForFindDriverIdOnMap(value) {
      this.passFindDriverIdOnMapTrigger = this.passFindDriverIdOnMapTrigger + 1;
      this.passFindDriverIdOnMap = value;
      //console.log("Console.vue - actionForFindDriverIdOnMap: ", value);
    },
    actionForFindPositionCoordinatesOnMap(value) {
      this.passFindPositionCoordinatesOnMap = value;
    },
    actionForOrderDetailByIdFromMessage(orderId) {
      this.passOrderId = orderId;
      this.passOrderTrigger = this.passOrderTrigger + 1;
      //this.tab = "tab-detail";
    },
    actionForChangeTabToMessageFromMessage(value) {
      //console.log("actionForChangeTabToMessageFromMessage");
      this.tab = "tab-messages";
    },
    actionForChangeTabToDetailFromMessage(value) {
      //console.log("actionForChangeTabToDetailFromMessage");
      this.tab = "tab-detail";
    },
    showBoardAddressOnMap(value) {
      this.passBoardAddressOnMap = value;
      //console.log("Console.vue: showBoardAddressOnMap / ", value);
    },
    showDestinationAddressOnMap(value) {
      this.passDestinationAddressOnMap = value;
      //console.log("Console.vue: showDestinationAddressOnMap / ", value);
    },
    showTransit1AddressOnMap(value) {
      this.passTransit1AddressOnMap = value;
      // console.log("Console.vue: showTransit1AddressOnMap / ", value);
    },
    showTransit2AddressOnMap(value) {
      this.passTransit2AddressOnMap = value;
      // console.log("Console.vue: showTransit2AddressOnMap / ", value);
    },
    showTransit3AddressOnMap(value) {
      this.passTransit3AddressOnMap = value;
      // console.log("Console.vue: showTransit3AddressOnMap / ", value);
    },
    showTransit4AddressOnMap(value) {
      this.passTransit4AddressOnMap = value;
      // console.log("Console.vue: showTransit4AddressOnMap / ", value);
    },
    showMarkerSettedOnMapToNew(value) {
      this.passMarkerSettedOnMapToNew = value;
      // console.log("Console.vue: showTransit1AddressOnMap / ", value);
    },
    changeMessageTab(
      messageType,
      fromDriverId,
      toDriverId,
      orderId,
      messageText
    ) {
      var messageData = [];
      messageData.messageType = messageType;
      messageData.fromDriverId = fromDriverId;
      messageData.toDriverId = toDriverId;
      messageData.orderId = orderId;
      messageData.messageText = messageText;
      //console.log(this.changeMessageTabAndSendMessageFromNotification);
      //console.log(messageData);
      //Prepnut na hlavnu zalozku "Messages"
      this.tab = "tab-messages";
      //Prepnut na podzalozku s typo spravy
      this.changeMessageTabAndSendMessageFromNotification = Object.assign(
        {},
        this.changeMessageTabAndSendMessageFromNotification,
        messageData
      );
      /*this.changeMessageTabAndSendMessageFromNotification.messageType = messageType;
      this.changeMessageTabAndSendMessageFromNotification.fromDriverId = fromDriverId;
      this.changeMessageTabAndSendMessageFromNotification.toDriverId = toDriverId;
      this.changeMessageTabAndSendMessageFromNotification.orderId = orderId;
      this.changeMessageTabAndSendMessageFromNotification.messageText = messageText;*/
    },
    getAutomatStatus() {
      //Zistenie nastavenia automatu
      const url = `/dispatching/automat/global`;
      if (this.fetchRequests == false) {
        axios
          .get(process.env.VUE_APP_API_BASE_URL + url)
          .then((response) => {
            //console.log("automat", response.data);
            if (response.data == true) {
              this.automatDisableDialog = true;
            } else {
              this.automatEnableDialog = true;
            }
          })
          .catch((e) => {
            console.log("getAutomatStatus error", e);
            //nasledujúci riadok je dočasne, kým nie je opravené API, potom zmazať
            this.automatDisableDialog = true;

            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "Console.settings.msgGetAutomatStatusError"
            );
            this.snackbar.color = "error";
            this.snackbar.timeout = 3000;
            console.error("UNAVAILABLE GET AUTOMAT STATUS " + e);
          });
      } else {
        const options = {
          method: "GET",
        };
        this.$fetchRequest(url, options)
          .then((response) => {
            if (!response.ok) {
              // Ak server vráti chybový status, môžete spracovať chybu tu
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((response) => {
            //console.log("automat", response);
            if (response == true) {
              this.automatDisableDialog = true;
            } else {
              this.automatEnableDialog = true;
            }
          })
          .catch((error) => {
            //nasledujúci riadok je dočasne, kým nie je opravené API, potom zmazať
            this.automatDisableDialog = true;

            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "Console.settings.msgGetAutomatStatusError"
            );
            this.snackbar.color = "error";
            this.snackbar.timeout = 3000;
            console.error("UNAVAILABLE GET AUTOMAT STATUS " + error);
          });
      }
    },
    changeAutomat(statusValue) {
      //var changeStatus = { enable: true };
      //changeStatus.enable = statusValue;
      const url = `/dispatching/automat/global?enable=`;
      if (this.fetchRequests == false) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + url + statusValue
            //,changeStatus
          )
          .then((response) => {
            //console.log("changeAutomat", response.data);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "Console.settings.msgChangeAutomatSuccess"
            );
            this.snackbar.color = "success";
            this.snackbar.timeout = 3000;
          })
          .catch((e) => {
            //console.log("error", e);
            console.error(
              "UNAVAILABLE CHANGE AUTOMAT STATUS to " +
                statusValue +
                " Error: " +
                e
            );
          });
      } else {
        const options = {
          method: "PUT",
        };
        this.$fetchRequest(url + statusValue, options)
          .then((response) => {
            if (!response.ok) {
              // Ak server vráti chybový status, môžete spracovať chybu tu
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((response) => {
            //console.log("changeAutomat", response);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "Console.settings.msgChangeAutomatSuccess"
            );
            this.snackbar.color = "success";
            this.snackbar.timeout = 3000;
          })
          .catch((error) => {
            console.error(
              "UNAVAILABLE CHANGE AUTOMAT STATUS to " +
                statusValue +
                " Error: " +
                error
            );
          });
      }
    },
    getPbxStatus() {
      this.pbxDialog = true;
    },
    changePbx(statusValue) {
      var pbxMode = "DISPATCHING";
      if (!this.module.driverDispatching && statusValue == "non-dispatching") {
        alert(this.$t("Console.settings.msgChangePbxModuleDisabled"));
        return;
      }
      switch (statusValue) {
        case "dispatching":
          pbxMode = "DISPATCHING";
          break;
        case "non-dispatching":
          pbxMode = "NON_DISPATCHING";
          break;
        case "offline":
          pbxMode = "OFFLINE";
          break;
        default:
          break;
      }
      const url = `/dispatching/pbx-mode?mode=`;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + url + pbxMode)
        .then((response) => {
          //msgChangePbxSuccess
          //msgChangePbxUnchanged
          //msgChangePbxError
          if (response.data.status == "OK") {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "Console.settings.msgChangePbxSuccess"
            );
            this.snackbar.color = "success";
            this.snackbar.timeout = 3000;
          } else {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "Console.settings.msgChangePbxUnchanged"
            );
            console.error(
              "UNAVAILABLE CHANGE PBX STATUS to " + response.data.message
            );
            this.snackbar.color = "warning";
            this.snackbar.timeout = 3000;
          }
        })
        .catch((e) => {
          //console.log("error", e);
          console.error(
            "UNAVAILABLE CHANGE PBX STATUS to " + statusValue + " Error: " + e
          );
        });
    },
    sendLogToServer() {
      //if consoleLog has records then send them to server
      if (this.consoleLog.length > 0) {
        axios
          .post(
            //process.env.VUE_APP_API_BASE_URL + `/dispatching/log`,
            `http://check.taxximo.com/dispatch-log.php`,
            this.consoleLog
          )
          .then((response) => {
            //console.log("sendLogToServer", response.data);
            this.consoleLog = [];
          })
          .catch((e) => {
            //console.log("error", e);
            console.error("UNAVAILABLE SEND LOG TO SERVER " + e);
          });
      }
    },
    showListOfDriversWorkRequests() {
      //Zobrazenie zoznamu pracovnych pozadavkov
      const url = `/dispatching/driver/worklog`;
      if (this.fetchRequests == false) {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog`,
            {
              params: this.getWorklogRequestItemsData,
            }
          )
          .then((response) => {
            this.worklogRequestList = response.data;
            if (this.worklogRequestList.length > 0) {
              this.workShiftRequestListDialog = true;
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("Console.msgNoWorklogRequests");
              this.snackbar.color = "info";
              this.snackbar.timeout = 3000;
            }
          })
          .catch((e) => {
            //this.errors.push(e);
          });
      } else {
        var paramsString = Object.entries(this.getWorklogRequestItemsData)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");
        const options = {
          method: "GET",
        };
        this.$fetchRequest(url + "?" + paramsString, options)
          .then((response) => {
            if (!response.ok) {
              // Ak server vráti chybový status, môžete spracovať chybu tu
              throw new Error(
                `Network response was not ok: ${response.statusText}`
              );
            }
            return response.json();
          })
          .then((response) => {
            this.worklogRequestList = response;
            if (this.worklogRequestList.length > 0) {
              this.workShiftRequestListDialog = true;
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("Console.msgNoWorklogRequests");
              this.snackbar.color = "info";
              this.snackbar.timeout = 3000;
            }
          })
          .catch((error) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("Console.msgNoWorklogRequests");
            this.snackbar.color = "info";
            this.snackbar.timeout = 3000;
            console.error("UNAVAILABLE GET WORKLOG REQUESTS " + error);
          });
      }
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //ak sa zmeni hodnota nastavenia "navadzacie ciary" tak uložiť do cookie
    setCookiePathDriverSettings(value) {
      //ak sa viaceri striedaju na jednom PC, tak trochu inteligentnejsie ulozit do cookie.
      //aby nezobrazenie ciar sa pamatalo najkratsie
      var cookieDaysToLive = 365;
      if (value == "NEVER") {
        cookieDaysToLive = 1;
      } else if (value == "INPROGRESS05MIN") {
        cookieDaysToLive = 7;
      } else if (value == "INPROGRESS10MIN") {
        cookieDaysToLive = 14;
      } else if (value == "INPROGRESS") {
        cookieDaysToLive = 30;
      } else if (value == "WAITING") {
        cookieDaysToLive = 60;
      } else if (value == "ALWAYS") {
        cookieDaysToLive = 365;
      }
      this.setCookie("pathDriverSettings", value, cookieDaysToLive);
      //console.log("setCookiePathDriverSettings: " + value + " days: " + cookieDaysToLive);
    },
    setCookieIconDriverSettings(value) {
      this.setCookie("iconDriverSettings", value, 14);
      //console.log("setCookieIconDriverSettings: " + value);
    },
    setCookieIconDriverLabelSettings(value) {
      this.setCookie("iconDriverLabelSettings", value, 14);
      //console.log("setCookieIconDriverSettings: " + value);
    },
    setCookieOrderMarkerSettings(value) {
      this.setCookie("orderMarkerSettings", value, 14);
      //console.log("setCookieOrderMarkerSettings: " + value);
    },
    setCookieOutbandPrefix(value) {
      //validate value if a least 1 character, if not then delete cookie
      if (value) {
        if (value.length == 0) {
          this.setCookie("outbandPrefix", "", 0);
        } else {
          //validate if is number, if not do not set and alert
          if (isNaN(value)) {
            alert(this.$t("Console.settings.msgOutbandPrefixNotNumber"));
          } else {
            this.setCookie("outbandPrefix", value, 365);
            //console.log("setCookieOutbandPrefix: " + value);
          }
        }
      } else {
        this.setCookie("outbandPrefix", "", 0);
      }
    },
    deleteCookieOutbandPrefix() {
      this.setCookie("outbandPrefix", "", 0);
    },
    setCookiePlayNotificationSound(value) {
      this.setCookie("playNotificationSound", value, 2);
      //console.log("setCookiePlayNotificationSound: " + value);
    },
    setCookiePlayNotificationSoundFile(value) {
      this.setCookie("playNotificationSoundFile", value, 14);
      //console.log("setCookiePlayNotificationSoundFile: " + value);
    },
    setCookieShowInfoPanel(value) {
      this.setCookie("showInfoPanel", value, 14);
      //console.log("setCookieShowInfoPanel: " + value);
    },
    setCookieDriverSpeed(value) {
      this.setCookie("driverSpeed", value, 14);
      //console.log("setCookieDriverSpeed: " + value);
    },
    setCookieDarkMapModel(value) {
      this.setCookie("switchDarkMapModel", value, 14);
      //console.log("setCookieDarkMapModel: " + value);
    },
    // Uloženie dat this.passOrdersToList do pamäte prehliadača ako BLOB
    storeOrderList(inputData) {
      //console.log("storeAllDataJson");
      if (inputData) {
        if (inputData.length == 0) return;
        localStorage.setItem("orderList", JSON.stringify(inputData));
      }
    },
    loadOrderList() {
      //console.log("loadOrderList");
      if (!navigator.onLine) {
        this.snackbar.message = this.$t("Console.msgInternetProblemDevice");
      } else {
        // Skontrolujeme, či je možné pripojiť sa na iné servery
        fetch("https://www.google.com/images/phd/px.gif", { mode: "no-cors" })
          .then(() => {
            // Server v datacentre je nedostupný
            this.snackbar.message = this.$t("Console.msgInternetProblemServer");
          })
          .catch((e) => {
            console.log(e);
            // Výpadok poskytovateľa internetových služieb
            this.snackbar.message = this.$t(
              "Console.msgInternetProblemConnection"
            );
          });
      }
      //Nacitanie objednavok z pamate prehliadaca
      var savedArray = JSON.parse(localStorage.getItem("orderList"));
      //console.log(savedArray);
      if (savedArray) {
        if (savedArray.length > 0) {
          this.passOrdersToList = savedArray;
        }
      }
      //Zobrazenie snackaru
      this.snackbar.show = "true";
      this.snackbar.color = "error";
      this.snackbar.timeout = -1;
    },
    onlineOrderList() {
      console.log("onlineOrderList");
      this.snackbar.show = "true";
      this.snackbar.message = this.$t("Console.msgInternetBackOnline");
      this.snackbar.color = "success";
      this.snackbar.timeout = 5000;
    },

    openDatacenterStatus() {
      window.open("https://status.websupport.sk/", "_blank");
    },
  },

  beforeUnmount() {
    //console.log("console destroyed");
  },
  unmounted() {
    //console.log("console unmounted");
  },
  beforeRouteLeave(to, from, next) {
    //console.log("console beforeRouteLeave");
    //console.log(from);
    /*const answer = window.confirm("Do you really want to leave?");
    if (answer) {*/
    clearInterval(this.refreshTimerOperational);
    clearInterval(this.refreshTimerMessages);
    this.sendLogToServer();
    next();
    /* } else {
      next(false);
    }*/
  },
};
</script>

<!--<style scoped src="../assets/console.css"></style>-->
